<template>
  <div class="error-report-wrapper">
    <div class="error-report-container">
      <div class="top-part">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.go(-1)"
        >
        <h4>{{ $t('error-report.machines-report.type') }}</h4>
      </div>
      <ul class="categories-list">
        <li
          v-for="equipmentType in dependentDecision"
          :key="equipmentType.id"
          class="category-item"
          @click="decideType(equipmentType.id)"
        >
          <custom-card
            :creation-data="equipmentType"
          >
            {{ equipmentType.name }}
          </custom-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonIcon from '@/assets/arrow_button.svg'
const CustomCard = () => import('@/components/GlobalComponents/CustomCard')

export default {
  name: 'ErrorType',
  components: {
    CustomCard
  },
  data () {
    return {
      ButtonIcon,
      machineTypes: new Set(),
      equipmentTypes: [
        {
          id: 'MACHINE',
          name: this.$t('error-report.machines-report.machine'),
          value: 'MACHINE'
        },
        {
          id: 'BUILDING_EQUIPMENT',
          name: this.$t('error-report.machines-report.building'),
          value: 'BUILDING_EQUIPMENT'
        },
        {
          id: 'OTHER_EQUIPMENT',
          name: this.$t('error-report.machines-report.devices'),
          value: 'OTHER_EQUIPMENT'
        },
        {
          id: 'WORKSHOP',
          name: this.$t('error-report.machines-report.workshop'),
          value: 'WORKSHOP'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData']),
    dependentDecision () {
      let res = []
      if (['HEAD_MAINTAINER', 'ADMIN'].includes(this.getUserData.role)) {
        res = this.equipmentTypes
      } else {
        res = this.equipmentTypes.slice(0, 3)
      }
      return res.filter(({ value }) => this.machineTypes.has(value))
    }
  },
  created () {
    this.getMachineData()
  },
  methods: {
    async getMachineData () {
      const { hallId } = this.$route.params
      try {
        const { data } = await this.$axios.get(`/machines?filter.hallId=${hallId}&select=type`)
        this.machineTypes = new Set(data.map(machine => machine.type))
        if (this.machineTypes.size === 1) {
          this.$router.replace({
            name: 'selectMachine',
            params: {
              hallId,
              type: this.dependentDecision[0].id
            }
          })
        }
      } catch (err) {
        console.error(err)
      }
    },
    decideType (type) {
      const { hallId } = this.$route.params
      console.log(this.equipmentTypes.find(typeName => typeName.id === type).name)
      this.$store.commit('ErrorReport/SET_TYPENAME', this.equipmentTypes.find(typeName => typeName.id === type).name)
      this.$router.push({
        name: 'selectMachine',
        params: {
          hallId,
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-report-wrapper {
  width: 100%;
  padding-top: 128px; // header + breadcrumbs

  div.error-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.top-part {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 53px;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }
    }

    ul.categories-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      list-style-type: none;
      margin-top: 64px;
      padding: 0;

      li.category-item {
        margin: 0 16px 16px 0;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-report-wrapper {
    position: relative;
    padding: 64px 0 100px 0;

    div.error-report-container {
      padding: 0;

      div.top-part {
        margin-top: 0;
        padding: 24px;

        img.back-button {
          position: fixed;
          bottom: 100px;
          right: 16px;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
      }

      ul.categories-list {
        flex-direction: column;
        margin-top: 0;

        li.category-item {
          margin: 0;
          border-bottom: 1px solid $primary-lightgrey;
        }
      }
    }
  }
}
</style>
